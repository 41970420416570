import { createColumn } from "../../../../components/Table";

export const desktopTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "time",
    header: () => "Time",
    cell: (info) => info.renderValue(),
    size: 80,
  }),
  createColumn({
    keyName: "interval",
    header: () => "Interval",
    cell: (info) => info.renderValue(),
    size: 80,
  }),
  createColumn({
    keyName: "source",
    header: () => "Source",
    cell: (info) => info.renderValue(),
    size: 80,
  }),
];
