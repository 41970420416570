import { IOption, SelectDropdown, TextField } from "@patientmpower/spiro";
import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { ISurveyQuestion } from "../../../../../../../../../@types/Surveys";
import { BlackPlus } from "../../../../../../../../../assets/icons/BlackPlus";
import { CustomIconButton } from "../../../../../../../../../components/CustomIconButton";
import { SeparationLine } from "../../../../SurveyBuilder.styles";
import { valueInputCss } from "../OptionSelectQuestion/OptionSelectQuestion.styles";
import {
  SliderContainer,
  LabelInputCss,
  OptionRowRight,
  OptionRowLeft,
  ValueInputCss,
  OptionnRowContainer,
  ValueParagraph,
  LabelParagraph,
  DropdownRow,
  ScoreInterval,
  ScoreContainer,
  IntervalInputCss,
  IntervalsErrorMessage,
} from "./SliderQuestion.styles";

interface ISliderQuestionProps {
  onAttributesChange: any;
  onOptionsChange: any;
  question?: ISurveyQuestion;
  triggerValidation: boolean | undefined;
  setSliderQuestionDetailsAreValidated: any;
  hasScoringIntervals?: boolean;
}

export interface ISliderInterval {
  start?: number;
  end?: number;
  score?: number;
}

export function SliderQuestion({
  onAttributesChange,
  onOptionsChange,
  question,
  triggerValidation,
  setSliderQuestionDetailsAreValidated,
  hasScoringIntervals,
}: ISliderQuestionProps) {
  const [sliderDirection, setSliderDirection] = useState<string>("Horizontal");

  const [intervals, setIntervals] = useState<ISliderInterval[]>([
    { start: undefined, end: undefined, score: undefined },
  ]);

  const [sliderMin, setSliderMin] = useState<string>("");
  const [sliderMax, setSliderMax] = useState<string>("");

  const [intervalErrors, setIntervalErrors] = useState<string | null>(null);

  const questionTypeOptions: IOption[] = [
    { label: "Vertical", value: "Vertical", key: uuidv4() },
    { label: "Horizontal", value: "Horizontal", key: uuidv4() },
  ];

  const initialValues = {
    options: [
      {
        order: 1,
        label: "",
        value: "",
      },
      {
        order: 2,
        label: "",
        value: "",
      },
    ],
    attributes: hasScoringIntervals
      ? [
          { name: "Step", value: "" },
          { name: "Orientation", value: "Horizontal" },
          { name: "Intervals", value: "" },
        ]
      : [
          { name: "Step", value: "" },
          { name: "Orientation", value: "Horizontal" },
        ],
  };

  const optionsSelectValidation = yup.object().shape({
    options: yup.array().of(
      yup.object().shape({
        order: yup.number(),
        label: yup.string().required("Required"),
        value: yup.number().typeError("Must be a number").required("Required"),
      })
    ),
    attributes: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required("required"),
          value: yup.string().required("required"),
        })
      )
      .test("Intervals error", () => {
        if (intervalErrors !== null) {
          return false;
        }

        intervals.map((interval) => {
          if (interval.start === undefined || interval.end === undefined)
            return false;

          if (
            interval.start < parseInt(sliderMin, 10) ||
            interval.start > parseInt(sliderMax, 10) ||
            interval.end < parseInt(sliderMin, 10) ||
            interval.end > parseInt(sliderMax, 10)
          ) {
            return false;
          }

          return true;
        });

        return true;
      }),
  });

  const sliderFormik = useFormik({
    initialValues,
    validationSchema: optionsSelectValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (question) {
      sliderFormik.setFieldValue("options", question.options);
      sliderFormik.setFieldValue("attributes", question.attributes);
      if (question.attributes) {
        setSliderDirection(
          question.attributes.find(
            (attribute) => attribute?.name === "Orientation"
          )?.value
        );

        const intervals = question.attributes.find(
          (attribute) => attribute?.name === "Intervals"
        )?.value;

        const parsedIntervals = JSON.parse(intervals) as ISliderInterval[];

        setIntervals(parsedIntervals);
      }
    }
  }, [question]);

  useEffect(() => {
    onOptionsChange(sliderFormik.values.options);
    onAttributesChange(sliderFormik.values.attributes);
  }, [sliderFormik.values]);

  const checkIfStringInputCanBeFloat = (valueToBeChecked: string) => {
    const parsedValue = parseFloat(valueToBeChecked);
    if (Number.isNaN(parsedValue)) {
      return false;
    }
    return true;
  };

  const shouldShowErrorMessage = (
    index: number,
    field: "label" | "value" | "step"
  ) => {
    if (sliderFormik.touched.options) {
      if (field === "label") {
        return sliderFormik.touched.options[index]
          ? getIn(sliderFormik.errors.options?.[index], "label")
          : "";
      }
      if (field === "value") {
        return sliderFormik.touched.options[index]
          ? getIn(sliderFormik.errors.options?.[index], "value")
          : "";
      }
    }
    if (sliderFormik.touched.attributes) {
      if (field === "step") {
        if (sliderFormik.values.attributes[index].value === "") {
          return sliderFormik.touched.attributes[index]
            ? getIn(sliderFormik.errors.attributes?.[index], "value")
            : "";
        }

        if (
          !checkIfStringInputCanBeFloat(
            sliderFormik.values.attributes[index].value
          )
        ) {
          return "Must be a number";
        }
      }
    }
    return "";
  };

  useEffect(() => {
    if (triggerValidation !== undefined) {
      sliderFormik.submitForm();
    }
  }, [triggerValidation]);

  useEffect(() => {
    if (sliderFormik.dirty) {
      if (
        sliderFormik.errors.options !== undefined ||
        sliderFormik.errors.attributes !== undefined
      ) {
        setSliderQuestionDetailsAreValidated(false);
      } else {
        const index = sliderFormik.values.attributes.findIndex(
          (attribute) => attribute.name === "Step"
        );
        if (
          checkIfStringInputCanBeFloat(
            sliderFormik.values.attributes[index].value
          )
        ) {
          setSliderQuestionDetailsAreValidated(true);
        } else {
          setSliderQuestionDetailsAreValidated(false);
        }
      }
    }
  }, [
    sliderFormik.errors.options,
    sliderFormik.errors.attributes,
    sliderFormik.values,
  ]);

  const validateIntervals = (intervals: ISliderInterval[]) => {
    const tempIntervals = [...intervals];

    tempIntervals.sort(
      (a, b) =>
        (a.start ?? Number.MIN_SAFE_INTEGER) -
        (b.start ?? Number.MIN_SAFE_INTEGER)
    );

    for (let i = 0; i < tempIntervals.length - 1; i += 1) {
      const currentEnd = tempIntervals[i].end ?? Number.MAX_SAFE_INTEGER;
      const currentStart = tempIntervals[i].start ?? Number.MIN_SAFE_INTEGER;
      const nextStart = tempIntervals[i + 1].start ?? Number.MIN_SAFE_INTEGER;

      if (currentStart > currentEnd || currentEnd < currentStart) {
        return "Invalid intervals";
      }

      if (currentEnd >= nextStart) {
        return "Overlapping intervals";
      }

      if (currentEnd + 1 !== nextStart) {
        return "Gap between intervals";
      }
    }

    return null;
  };

  const handleInputChange = (
    index: number,
    field: "start" | "end" | "score",
    value: string
  ) => {
    const newIntervals: ISliderInterval[] = [];

    intervals.forEach((interval, idx) => {
      if (idx === index) {
        const tempInterval = { ...interval };
        tempInterval[field] = parseInt(value, 10) ?? undefined;

        newIntervals.push(tempInterval);
        return;
      }

      newIntervals.push(interval);
    });

    setIntervals(newIntervals);

    if (
      newIntervals[index].start !== undefined &&
      newIntervals[index].end !== undefined
    ) {
      const validationError = validateIntervals(newIntervals);
      setIntervalErrors(validationError);
    }

    const atributesIndex = sliderFormik.values.attributes.findIndex(
      (attribute) => attribute.name === "Intervals"
    );

    if (atributesIndex !== -1) {
      const newAttributes = [...sliderFormik.values.attributes];
      newAttributes[atributesIndex].value = JSON.stringify(newIntervals);
      sliderFormik.setFieldValue("attributes", newAttributes);
    }
  };

  const handleAddInterval = () => {
    const newIntervals = [
      ...intervals,
      { start: undefined, end: undefined, score: undefined },
    ];
    setIntervals(newIntervals);
  };

  const handleInputErrorMessage = (
    intervalType: string,
    intervalValue?: number
  ): string => {
    const sliderMin = sliderFormik.values.options[0].value;
    const sliderMax = sliderFormik.values.options[1].value;

    if (intervalType === "start") {
      if (intervalValue === undefined) return "";

      if (
        intervalValue < parseInt(sliderMin, 10) ||
        intervalValue > parseInt(sliderMax, 10)
      ) {
        return "Outside of range";
      }
    }

    if (intervalValue === undefined) return "";

    if (
      intervalValue < parseInt(sliderMin, 10) ||
      intervalValue > parseInt(sliderMax, 10)
    ) {
      return "Outside of range";
    }

    return "";
  };

  return (
    <SliderContainer>
      <FormikProvider value={sliderFormik}>
        <FieldArray name="options">
          {() => (
            <>
              <p> </p>
              {sliderFormik.values.options?.map((option, index) => (
                <OptionnRowContainer key={index}>
                  <OptionRowLeft>
                    {index === 0 && (
                      <LabelParagraph>Minimum label</LabelParagraph>
                    )}
                    {index === 1 && (
                      <LabelParagraph>Maximum label</LabelParagraph>
                    )}
                    <TextField
                      maxLength={40}
                      label=""
                      name={`options[${index}].label`}
                      placeholder="..."
                      value={sliderFormik.values.options[index].label as string}
                      onChange={sliderFormik.handleChange}
                      backgroudColor="white"
                      onBlur={sliderFormik.handleBlur}
                      className={LabelInputCss()}
                      errorMessage={shouldShowErrorMessage(index, "label")}
                    />
                  </OptionRowLeft>
                  <OptionRowRight>
                    {index === 0 && (
                      <ValueParagraph>Minimum value</ValueParagraph>
                    )}
                    {index === 1 && (
                      <ValueParagraph>Maximum value</ValueParagraph>
                    )}

                    <TextField
                      maxLength={3}
                      label=""
                      name={`options[${index}].value`}
                      placeholder="..."
                      value={sliderFormik.values.options[index].value}
                      onChange={(e) => {
                        sliderFormik.handleChange(e);
                        if (index === 0) {
                          setSliderMin(e.target.value);
                        } else {
                          setSliderMax(e.target.value);
                        }
                      }}
                      backgroudColor="white"
                      onBlur={sliderFormik.handleBlur}
                      className={ValueInputCss()}
                      errorMessage={shouldShowErrorMessage(index, "value")}
                    />
                  </OptionRowRight>
                </OptionnRowContainer>
              ))}
            </>
          )}
        </FieldArray>
        <OptionnRowContainer>
          <DropdownRow>
            <LabelParagraph>Orientation</LabelParagraph>
            <SelectDropdown
              options={questionTypeOptions}
              width={200}
              height={42}
              placeholder="Choose a type"
              value={sliderDirection}
              onValueChange={(value: string | string[]) => {
                setSliderDirection(value.toString());
                sliderFormik.setFieldValue(
                  `attributes[${sliderFormik.values.attributes.findIndex(
                    (attribute) => attribute?.name === "Orientation"
                  )}].value`,
                  value.toString()
                );
              }}
            />
          </DropdownRow>
          <OptionRowRight>
            <ValueParagraph>Step</ValueParagraph>
            <TextField
              maxLength={5}
              label=""
              name={`attributes[${sliderFormik.values.attributes.findIndex(
                (attribute) => attribute?.name === "Step"
              )}].value`}
              placeholder="..."
              value={
                sliderFormik.values.attributes.find(
                  (attribute) => attribute?.name === "Step"
                )?.value
              }
              onChange={sliderFormik.handleChange}
              backgroudColor="white"
              onBlur={sliderFormik.handleBlur}
              className={ValueInputCss()}
              errorMessage={shouldShowErrorMessage(
                sliderFormik.values.attributes.findIndex(
                  (attribute) => attribute?.name === "Step"
                ),
                "step"
              )}
            />
          </OptionRowRight>
        </OptionnRowContainer>

        {hasScoringIntervals ? (
          <>
            <SeparationLine style={{ width: "93%", margin: "40px 0" }} />

            <ScoreContainer>
              <p style={{ marginBottom: "24px" }}>Score Intervals</p>

              {intervals.map((interval, index) => (
                <ScoreInterval key={index}>
                  <TextField
                    label="Start"
                    placeholder="0"
                    value={interval.start}
                    onChange={(e) =>
                      handleInputChange(index, "start", e.target.value)
                    }
                    backgroudColor="white"
                    className={IntervalInputCss()}
                    type="number"
                    errorMessage={handleInputErrorMessage(
                      "start",
                      interval.start
                    )}
                  />
                  <TextField
                    label="End"
                    placeholder="100"
                    value={interval.end}
                    onChange={(e) =>
                      handleInputChange(index, "end", e.target.value)
                    }
                    backgroudColor="white"
                    className={IntervalInputCss()}
                    type="number"
                    errorMessage={handleInputErrorMessage("end", interval.end)}
                  />
                  <TextField
                    label="Score"
                    placeholder="3"
                    value={interval.score}
                    onChange={(e) =>
                      handleInputChange(index, "score", e.target.value)
                    }
                    backgroudColor="white"
                    className={valueInputCss()}
                    type="number"
                  />
                </ScoreInterval>
              ))}

              <CustomIconButton
                style={{
                  width: "auto",
                  borderRadius: "20px",
                  padding: "5px 10px 5px 10px",
                  marginTop: "10px",
                }}
                onClick={handleAddInterval}
              >
                <BlackPlus />
              </CustomIconButton>

              <IntervalsErrorMessage>{intervalErrors}</IntervalsErrorMessage>
            </ScoreContainer>
          </>
        ) : null}
      </FormikProvider>
    </SliderContainer>
  );
}
