import { AxiosResponse } from "axios";

import { IAvailableWards } from "../@types/User";
import { api } from "./api";

export const wardsService = {
  async getAvailableWards(): Promise<AxiosResponse<IAvailableWards[]>> {
    const url = "/Portal/Wards/GetAvailableWards";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getHospitalGroupWards(
    hospitalGroupId: number
  ): Promise<AxiosResponse<IAvailableWards[]>> {
    const url = `/Portal/Wards/GetHospitalGroupWards/${hospitalGroupId}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
