import { useQuery } from "@tanstack/react-query";

import {
  IPromsTableData,
  ISurveyPromsTableData,
  ISurveysForms,
  ISurveysResponse,
} from "../../@types/Proms";
import { promsService } from "../../services/promsService";
import { getLongFormattedDate } from "../../utils/dateFormatter";

export function getPatientPromsData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-proms-${patientId}`, patientId],
    async () => {
      if (!patientId) return undefined;

      const { data } = await promsService.getPatientProms(patientId);

      return data.surveys as ISurveysResponse[];
    }
  );

  const patientPromsData = data as ISurveysResponse[];

  return {
    proms: patientPromsData?.map((promsData) => {
      return {
        ...promsData,
        reminders: promsData.reminders ? "Active" : "Inactive",
        adherence: `${Math.round(promsData.adherence ?? 0)}%`,
        assigned: getLongFormattedDate(promsData.assigned),
        frequency: promsData.frequency ?? "-",
        surveyResult: "-",
        lastTotalScore: promsData.lastTotalScore ?? "-",
        lastCompleted: getLongFormattedDate(promsData.lastCompleted),
      } as IPromsTableData;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getPatientSurveyFormsData(patientId: number, surveyId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-survey-forms-${patientId}-${surveyId}`, patientId],
    async () => {
      if (!patientId || !surveyId) return undefined;

      const { data } = await promsService.getPatientSurveyForms(
        patientId,
        surveyId
      );

      return data.surveyForms as ISurveysForms[];
    }
  );

  const patientSurveyFormsData = data as ISurveysForms[];

  return {
    surveyForms: patientSurveyFormsData?.map((formsData) => {
      return {
        ...formsData,
        time: getLongFormattedDate(formsData.time),
      } as ISurveyPromsTableData;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
