export function LineDot(props: any) {
  const { cx, cy } = props;

  return (
    <svg
      x={cx && cx - 8}
      y={cy && cy - 8}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      cursor="pointer"
    >
      <rect x="2" y="6" width="12" height="4" rx="1.2" fill="#7562A8" />
    </svg>
  );
}
