import { IOption } from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useRef, DragEvent, useState, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { Notification } from "../../../../@types/Notification";
import { PatientPropChange } from "../../../../@types/Patient";
import { IOperationValue, IPatchOperation } from "../../../../@types/Request";
import { ArrowRight } from "../../../../assets/icons/ArrowRight";
import { ToastNotification } from "../../../../components/ToastNotification";
import {
  sexOptions,
  ethnicityOptions,
} from "../../../../constants/patientDetails";
import {
  usePatientDetailsDropdownItems,
  usePatientInfo,
  usePatientMonitoringInfo,
  useUserPreferencesData,
} from "../../../../hooks/queries/patients";
import { useModal } from "../../../../hooks/useModal";
import { patientService } from "../../../../services/patientService";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import { PatientCard, PatientMenuCard } from "../PatientMenuCard";
import { cardsCofigs } from "./cardsConfigs";
import { ConfirmChangesModal } from "./components/ConfirmChangesModal";
import {
  EditButton,
  ExpandButton,
  EditControlsButton,
  PatientLateralMenu,
  EditButtonContainer,
  EditControlsContainer,
  PatientLateralMenuCardContainer,
} from "./PatientLateralMenu.style";

type PatientLateralMenuDesktopProps = {
  open: boolean;
  onExpandClick: () => void;
  onEditingClick: (isEditing: boolean) => void;
  patientId: string | undefined;
};

export function PatientLateralMenuDesktop({
  open,
  patientId,
  onExpandClick,
  onEditingClick,
}: PatientLateralMenuDesktopProps) {
  const menuRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [patientCards, setPatientCards] = useState<PatientCard[]>([]);
  const [propsChanged, setPropsChanged] = useState<PatientPropChange[]>([]);
  const [lastPatientCards, setLastPatientCards] = useState<PatientCard[]>(
    [] as PatientCard[]
  );
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "30%",
  });

  const { patient, refetchPatientInfo } = usePatientInfo({ patientId });
  const { patientMonitoringInfo } = usePatientMonitoringInfo({ patientId });
  const { openModal, closeModal } = useModal();
  const { dropdownItems } = usePatientDetailsDropdownItems({ patientId });
  const { userPreferences, refetch } = useUserPreferencesData();

  const getLabelGender = (value?: string) => {
    const object = sexOptions.find((a) => value === a.value);
    return object?.label;
  };
  const getLabelEthnicity = (value?: string) => {
    const object = ethnicityOptions.find((a) => value === a.value);
    return object?.label;
  };
  const defaultCards = [
    {
      order: 1,
      active: true,
      name: "DOB",
      description: patient.birthDate
        ? getShortFormattedDate(patient.birthDate)
        : "-",
    },
    {
      order: 2,
      active: true,
      name: "Hospital ID",
      description: patient?.hospitalPatientId || "-",
    },
    {
      order: 3,
      active: true,
      name: "Patient ID",
      description: patient?.id.toString() || "-",
    },
    {
      order: 4,
      active: true,
      name: "Email",
      description: patient?.user?.email || "-",
    },
    {
      order: 5,
      active: true,
      name: "Last Review",
      description: patientMonitoringInfo?.latestReview || "-",
    },
    {
      order: 6,
      active: true,
      name: "Last Reviewed By",
      description: patientMonitoringInfo?.latestReviewedBy || "-",
    },
    {
      order: 7,
      active: true,
      name: "Hospital",
      description: patientMonitoringInfo?.hospitalName || "-",
    },
    {
      order: 8,
      active: true,
      name: "Last Used",
      description: patient.user?.lastUsed || "-",
    },
    {
      order: 9,
      active: false,
      name: "Weight (kg)",
      description: patient.user?.weight || "-",
    },
    {
      order: 10,
      active: false,
      name: "Height (cm)",
      description: patient.user?.height || "-",
    },
    {
      order: 11,
      active: false,
      name: "Country",
      description: patient.user?.country || "-",
    },
    {
      order: 12,
      active: false,
      name: "Condition",
      description: patient?.condition || "-",
    },
    {
      order: 13,
      active: false,
      name: "Caregiver name",
      description: patient.user?.caregiverName || "-",
    },
    {
      order: 14,
      active: false,
      name: "Caregiver phone number",
      description: patient.user?.caregiverPhoneNumber || "-",
    },
    {
      order: 15,
      active: false,
      name: "Goals",
      description: patientMonitoringInfo?.goals || "-",
    },
    {
      order: 16,
      active: false,
      name: "Phone",
      description: patient?.user?.phoneNumber || "-",
    },
    {
      order: 17,
      active: true,
      name: "Gender",
      description: getLabelGender(patient?.gender) || "-",
    },
    {
      order: 18,
      active: true,
      name: "Ethnicity",
      description: getLabelEthnicity(patient?.ethnicity) || "-",
    },
  ];

  const setDefaultCardsOrder = () => {
    setPatientCards(defaultCards);
  };

  const getObjectByName = (name: string) => {
    return defaultCards.find((a) => a.name === name);
  };

  useEffect(() => {
    if (!patient && !patientMonitoringInfo) return;

    if (
      userPreferences &&
      userPreferences.patientDetailsCardsOrder &&
      userPreferences.patientDetailsCardsOrder !== "[]"
    ) {
      const parsedPatientCards = JSON.parse(
        userPreferences.patientDetailsCardsOrder
      ) as PatientCard[];

      const temporaryPatientCards: PatientCard[] = [];

      if (parsedPatientCards) {
        parsedPatientCards.forEach((card) => {
          const temporaryCard = { ...card };

          switch (card.name) {
            case "DOB":
              temporaryCard.description = patient.birthDate
                ? getShortFormattedDate(patient.birthDate)
                : "-";
              break;
            case "Hospital ID":
              temporaryCard.description = patient?.hospitalPatientId || "-";
              break;
            case "Patient ID":
              temporaryCard.description = patient?.id.toString() || "-";
              break;
            case "Email":
              temporaryCard.description = patient?.user?.email || "-";
              break;
            case "Last Reviewed By":
              temporaryCard.description =
                patientMonitoringInfo?.latestReviewedBy || "-";
              break;
            case "Last Review":
              temporaryCard.description =
                patientMonitoringInfo?.latestReview || "-";
              break;
            case "Hospital":
              temporaryCard.description =
                patientMonitoringInfo?.hospitalName || "-";
              break;
            case "Last Used":
              temporaryCard.description = patient.user?.lastUsed || "-";
              break;
            case "Weight (kg)":
              temporaryCard.description = patient.user?.weight || "-";
              break;
            case "Height (cm)":
              temporaryCard.description = patient.user?.height || "-";
              break;
            case "Country":
              temporaryCard.description = patient.user?.country || "-";
              break;
            case "Condition":
              temporaryCard.description = patient?.condition || "-";
              break;
            case "Caregiver name":
              temporaryCard.description = patient.user?.caregiverName || "-";
              break;
            case "Caregiver phone number":
              temporaryCard.description =
                patient.user?.caregiverPhoneNumber || "-";
              break;
            case "Goals":
              temporaryCard.description = patientMonitoringInfo?.goals || "-";
              break;
            case "Phone":
              temporaryCard.description = patient?.user?.phoneNumber || "-";
              break;
            case "Gender":
              temporaryCard.description =
                getLabelGender(patient?.gender) || "-";
              break;
            case "Ethnicity":
              temporaryCard.description =
                getLabelEthnicity(patient?.ethnicity) || "-";
              break;
            default:
              return;
          }

          temporaryPatientCards.push(temporaryCard);
        });
        // Add in any default cards names that are not in the parsed list
        const listOfCardNames = defaultCards.map((item) => {
          return item.name;
        });
        const listOfTempCardNames = temporaryPatientCards.map((item) => {
          return item.name;
        });
        // Get the names that are in the default list but not the parsed list
        const difference = listOfCardNames.filter(
          (x) => !listOfTempCardNames.includes(x)
        );

        difference.forEach((name) => {
          // Get the object from the default list by name
          const cardObject = getObjectByName(name);
          if (cardObject) {
            cardObject.active = false;
            const object = temporaryPatientCards.find(
              (a) => a.order === cardObject.order
            );
            if (!object) {
              temporaryPatientCards.push(cardObject);
            } else {
              // If object order conflicts, then add it to the end (highest order)
              const orders = temporaryPatientCards.map((object) => {
                return object.order;
              });
              cardObject.order = Math.max(...orders) + 1;
              temporaryPatientCards.push(cardObject);
            }
          }
        });
        setPatientCards(temporaryPatientCards);
      } else {
        setDefaultCardsOrder();
      }
    } else {
      setDefaultCardsOrder();
    }
  }, [patient, patientMonitoringInfo, userPreferences]);

  const saveLastPatiendCardsConfig = () => {
    setLastPatientCards([...patientCards]);
  };

  const handleOnEditClick = () => {
    saveLastPatiendCardsConfig();
    setIsEditing(true);
    onEditingClick(true);
  };

  const updateCardsOrderByPosition = () => {
    const newOrderedCards: PatientCard[] = [];

    const cards: NodeListOf<HTMLDivElement> =
      document.querySelectorAll("[dragging]");

    patientCards.forEach((patientCard) => {
      cards.forEach((htmlCard, index) => {
        if (htmlCard.getAttribute("name") === patientCard.name) {
          const newPatientCard = { ...patientCard };

          newPatientCard.order = index + 1;

          newOrderedCards.push(newPatientCard);
        }
      });
    });

    return newOrderedCards;
  };

  const handleOnSave = async (
    onlyCardsOrder: boolean,
    sendEnrolmentEmail: boolean,
    newOrder: PatientCard[]
  ) => {
    const patientOperations: IPatchOperation[] = [];
    const cardsOrderToSave: PatientCard[] = [];

    if (!onlyCardsOrder) {
      propsChanged.forEach((prop) => {
        let newValue: IOperationValue;

        const propIndex = cardsCofigs.findIndex(
          (x) => x.name === prop.propName
        );

        if (prop.propName === "Country") {
          const newCountry = dropdownItems.countryItems?.find(
            (x) => x.itemName === prop.newValue
          );

          const oldCountry = dropdownItems.countryItems?.find(
            (x) => x.itemName === prop.oldValue
          );

          newValue = {
            newValue: newCountry?.itemId.toString(),
            oldValue: oldCountry?.itemId.toString(),
          };
        } else if (prop.propName === "Gender") {
          const newSex = sexOptions.find((x) => x.label === prop.newValue);
          const oldSex = sexOptions.find((x) => x.label === prop.oldValue);

          newValue = {
            newValue: newSex?.value,
            oldValue: oldSex?.value,
          };
        } else if (prop.propName === "Ethnicity") {
          const newEthnicity = ethnicityOptions.find(
            (x) => x.label === prop.newValue
          );
          const oldEthnicity = ethnicityOptions.find(
            (x) => x.label === prop.oldValue
          );

          newValue = {
            newValue: newEthnicity?.value,
            oldValue: oldEthnicity?.value,
          };
        } else if (prop.propName === "Condition") {
          const newValues: any[] = [];
          const oldValues: any[] = [];

          const splittedNewValues = prop.newValue?.split(", ");
          const splittedOldValues = prop.oldValue?.split(", ");

          splittedNewValues?.forEach((newValue) => {
            const conditionId = dropdownItems.conditionItems?.find(
              (x) => x.itemName === newValue
            )?.itemId;

            newValues.push(conditionId);
          });

          splittedOldValues?.forEach((oldValue) => {
            const conditionId = dropdownItems.conditionItems?.find(
              (x) => x.itemName === oldValue
            )?.itemId;

            oldValues.push(conditionId);
          });

          newValue = {
            newValue: newValues.join(", "),
            oldValue: oldValues.join(", "),
          };
        } else if (prop.propName === "DOB") {
          newValue = {
            newValue: dayjs(prop.newValue).format("YYYY/MM/DD"),
            oldValue: dayjs(prop.oldValue).format("YYYY/MM/DD"),
          };
        } else {
          newValue = {
            newValue: prop.newValue?.toString(),
            oldValue: prop.oldValue.toString(),
            sendEnrolmentEmail:
              prop.propName === "Email" ? sendEnrolmentEmail : false,
          };
        }

        patientOperations.push({
          op: "add",
          path: cardsCofigs[propIndex].path ?? "",
          value: newValue,
        });
      });
    }

    newOrder.forEach((card) => {
      const newCard = { ...card };

      delete newCard.description;

      cardsOrderToSave.push(newCard);
    });

    const cardsOrderJson = JSON.stringify(cardsOrderToSave);

    const result = await patientService.updatePatientDetailsAndCardsOrder(
      patient.id,
      patientOperations,
      cardsOrderJson
    );

    if (result.status >= 200 && result.status < 300) {
      setPropsChanged([]);
      refetch();
      refetchPatientInfo();

      setNotification({
        show: true,
        message: "Patient details successfully updated",
        type: "success",
        width: "max-content",
        rightMargin: "20%",
      });
      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: "max-content",
          rightMargin: "20%",
        });
      }, 3500);
    } else {
      setNotification({
        show: true,
        message: "Something went wrong while updating, please try again",
        type: "error",
        width: "max-content",
        rightMargin: "65%",
      });
      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: "max-content",
          rightMargin: "65%",
        });
      }, 3500);
    }

    setIsEditing(false);
    onEditingClick(false);
    closeModal();
  };

  const resetLastCardConfig = () => {
    setPatientCards(lastPatientCards);
  };

  const handleOnCancelEditionClick = () => {
    resetLastCardConfig();
    setPropsChanged([]);
    setIsEditing(false);
    onEditingClick(false);
  };

  const handleModalOnClose = () => {
    resetLastCardConfig();
    setPropsChanged([]);
    closeModal();
  };

  const handleOnSaveEditionClick = () => {
    const newOrder = updateCardsOrderByPosition();

    openModal(
      <ConfirmChangesModal
        propsChanged={propsChanged}
        onClose={handleModalOnClose}
        onSave={(onlyCardsOrder: boolean, sendEnrolmentEmail: boolean) =>
          handleOnSave(onlyCardsOrder, sendEnrolmentEmail, newOrder)
        }
      />,
      {
        width: "611px",
        height: "auto",
        maxHeight: "90vh",
      }
    );
  };

  const getNextCard = (currentCardPosY: number) => {
    let result: HTMLDivElement | undefined;

    const cards: NodeListOf<HTMLDivElement> =
      document.querySelectorAll("[dragging='false']");

    cards.forEach((card) => {
      const isNotVisibleCard = card.className.includes("isVisible-false");
      if (isNotVisibleCard) return;

      const cardBox = card.getBoundingClientRect();
      const cardBoxCenterY = cardBox.y + cardBox.height / 2;

      const currentCardIsOverOrInsideThisCard =
        currentCardPosY >= cardBoxCenterY;

      if (currentCardIsOverOrInsideThisCard) result = card;
    });

    return result;
  };

  const handleOnDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Avoid system not-alowed cursor

    const nextCard = getNextCard(event.clientY);

    const dragging: HTMLDivElement | null =
      document.querySelector("[dragging='true']");

    if (dragging) {
      if (nextCard) {
        nextCard.insertAdjacentElement("afterend", dragging);
      } else {
        menuRef.current?.prepend(dragging);
      }
    }
  };

  const cardsOrderComparer = (a: PatientCard, b: PatientCard) => {
    if (a.active !== b.active) {
      if (a.active) return -1;

      if (b.active) return 1;
    }

    if (a.order === b.order) return 0;

    return a.order > b.order ? 1 : -1;
  };

  const handleIsEditable = (card: PatientCard) => {
    const prop = cardsCofigs.find((x) => x.name === card.name);
    const patientCard = patientCards.find((x) => x.name === card.name);

    if (isEditing && prop?.isEditable && patientCard?.active) return true;

    return false;
  };

  const handleOnCardButtonClick = (card: PatientCard) => {
    const newPatientCard: PatientCard = { ...card, active: !card.active };

    const activeCards: PatientCard[] = [];
    const inactiveCards: PatientCard[] = [];

    patientCards.forEach((patientCard) => {
      const isIgnoringCardToChange = patientCard.order !== card.order;

      if (isIgnoringCardToChange) {
        if (patientCard.active) activeCards.push(patientCard);
        else inactiveCards.push(patientCard);
      }
    });

    const newCards = [...activeCards, newPatientCard, ...inactiveCards];

    const newOrderedCards = newCards.map((card, idx) => {
      const order = idx + 1;
      return { ...card, order };
    });

    setPatientCards(newOrderedCards);
  };

  const handleOnPropEdit = (
    propName: string,
    newValue: string | null,
    oldValue: string
  ) => {
    if (oldValue.toString() === newValue?.trim()) return;

    const propertyIndex = propsChanged.findIndex(
      (x) => x.propName === propName
    );

    const temporaryPropsObj = propsChanged;

    if (newValue !== null) {
      if (propertyIndex !== -1) {
        temporaryPropsObj[propertyIndex] = {
          propName,
          newValue,
          oldValue,
        };
      } else {
        temporaryPropsObj.push({
          propName,
          newValue,
          oldValue,
        });
      }
    } else {
      temporaryPropsObj.splice(propertyIndex, 1);
    }

    setPropsChanged(temporaryPropsObj);
  };

  const handleDropdownItems = (card: PatientCard) => {
    const options: IOption[] = [];

    if (card.name === "Country" && dropdownItems?.countryItems) {
      dropdownItems?.countryItems?.forEach((item, index) => {
        options.push({
          label: item.itemName,
          value: item.itemId.toString(),
          key: `${item.itemName}-${item.itemId}-${index}`,
        });
      });
    }

    if (card.name === "Condition" && dropdownItems?.conditionItems) {
      dropdownItems?.conditionItems?.forEach((item, index) => {
        options.push({
          label: item.itemName,
          value: item.itemId.toString(),
          key: `${item.itemName}-${item.itemId}-${index}`,
        });
      });
    }

    if (card.name === "Gender") {
      sexOptions.forEach((item, index) => {
        options.push({
          label: item.label,
          value: item.value,
          key: `${item.label}-${item.value}-${index}`,
        });
      });
    }

    if (card.name === "Ethnicity") {
      ethnicityOptions.forEach((item, index) => {
        options.push({
          label: item.label,
          value: item.value,
          key: `${item.label}-${item.value}-${index}`,
        });
      });
    }

    return options;
  };

  const childComponents = useMemo(() => {
    if (!patientCards) return [];

    return patientCards.sort(cardsOrderComparer).map((card) => {
      return (
        <PatientMenuCard
          key={uuidv4()}
          card={card}
          isEditing={isEditing}
          isEditable={handleIsEditable(card)}
          onDragOver={handleOnDragOver}
          onCardButtonClick={() => handleOnCardButtonClick(card)}
          onPropEdit={handleOnPropEdit}
          dropdownItems={handleDropdownItems(card)}
          onError={(error) => {
            setHasError(error);
          }}
        />
      );
    });
  }, [patientCards, isEditing]);

  return (
    <>
      <PatientLateralMenu isLateralPatientMenuOpenOnDesktop={open}>
        {!isEditing && (
          <EditButtonContainer>
            <EditButton onClick={handleOnEditClick}>Edit</EditButton>
          </EditButtonContainer>
        )}

        <PatientLateralMenuCardContainer
          ref={menuRef}
          isLateralPatientMenuOpenOnDesktop={open}
        >
          {childComponents}

          <div style={{ height: isEditing ? "95px" : "80px", width: "100%" }} />
        </PatientLateralMenuCardContainer>

        {isEditing && (
          <EditControlsContainer>
            <EditControlsButton
              onClick={handleOnSaveEditionClick}
              primary
              disabled={hasError}
            >
              Save
            </EditControlsButton>

            <EditControlsButton onClick={handleOnCancelEditionClick}>
              Cancel
            </EditControlsButton>
          </EditControlsContainer>
        )}

        {!isEditing && (
          <ExpandButton isExpanded={open} onClick={onExpandClick}>
            <ArrowRight />
          </ExpandButton>
        )}
      </PatientLateralMenu>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin={notification.rightMargin}
        />
      ) : null}
    </>
  );
}
