import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  IPatient,
  IPatientDetailsDropdownItems,
  IPatientInfo,
  IPatientListVariants,
  IPatientMonitoringInfo,
  PatientAlertsTableContent,
} from "../../@types/Patient";
import { IUserPreferences } from "../../@types/Preferences";
import { patientService } from "../../services/patientService";
import { userService } from "../../services/userService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { tableCellNullValueParser } from "../../utils/tableDataFormatter";

export function usePatientList(
  { variant }: IPatientListVariants,
  refetchOnMount: "always" | boolean = "always"
) {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery(
    [`patient-list-${variant}`, variant],
    async () => {
      const serverResponse = await patientService.getPatients({ variant });

      const data = serverResponse.data.result;

      // TODO: add specific type for the API response when we move to the 2.0 API.
      const patientsParsed = data.map((patient: IPatient) => {
        const signUpDate = tableCellNullValueParser(
          patient?.signUpDate,
          getLongFormattedDate
        );

        const lastUsed = tableCellNullValueParser(
          patient?.lastUsed,
          getLongFormattedDate
        );

        const surveyReceived = tableCellNullValueParser(
          patient?.surveyReceived,
          getLongFormattedDate
        );

        const hospitalId = tableCellNullValueParser(patient?.hospitalPatientId);

        return {
          lastUsed,
          hospitalId,
          signUpDate,
          id: patient.id,
          lastReviewedBy: patient.lastReviewedBy,
          name: patient.name,
          flagged: patient.flagged,
          alerts: patient?.alerts,
          surveyReceived,
          surveyResult: patient?.surveyResult,
          isPasswordSet: patient.isPasswordSet,
        };
      });

      return { patients: patientsParsed as IPatient[] };
    },
    { refetchOnMount }
  );

  return {
    patients: data?.patients,
    isLoading,
    isError,
    isRefetching,
    refetch,
  };
}

export function usePatientInfo({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError, isRefetching, refetch } = useQuery(
    [`patient-info-${patientId}`, patientId],
    async () => {
      if (!patientId) return { condition: "" } as unknown as IPatientInfo;

      const { data } = await patientService.getPatientById(patientId);
      const { lastUsed } = data;

      const patientConditions: string[] = [];

      data?.conditions.forEach((condition: any) => {
        patientConditions.push(condition.name);
      });

      return {
        id: data?.id,
        birthDate: data?.dob,
        flagged: data?.flagged,
        condition: patientConditions.join(", "),
        hospitalId: data?.hospitalId,
        hospitalPatientId: data?.hospitalPatientId,
        gender: data?.gender,
        ethnicity: data?.ethnicity,
        user: {
          name: data?.name,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          lastUsed: lastUsed ? getLongFormattedDate(lastUsed) : null,
          weight: data?.weight,
          height: data?.height,
          country: data?.country,
          caregiverName: data?.caregiverName,
          caregiverPhoneNumber: data?.caregiverPhoneNumber,
        },
      } as IPatientInfo;
    }
  );

  return {
    patient: data as IPatientInfo,
    isFetching,
    isError,
    refetchPatientInfo: refetch,
    isRefetching,
  };
}

export function usePatientMonitoringInfo({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError, refetch } = useQuery(
    [`patient-monitoring-info-${patientId}`, patientId],
    async () => {
      if (!patientId) return {} as unknown as IPatientMonitoringInfo;

      const { data } = await patientService.getPatientMonitoringInfo(patientId);

      return {
        hospitalName: data?.hospitalName,
        latestReviewedBy: data?.latestReviewedBy,
        latestReview: getLongFormattedDate(data?.latestReview) || null,
        goals: data?.goals,
      } as IPatientMonitoringInfo;
    }
  );

  return {
    patientMonitoringInfo: data as IPatientMonitoringInfo,
    isFetching,
    isError,
    refetchMonitoringInfo: refetch,
  };
}

export function getPatientAlertsData(id: string | number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-alerts-table-${id}`, id],
    async () => {
      if (!id) return [] as PatientAlertsTableContent[];

      const { data } = await patientService.getPatientAlerts(id);

      return data?.result as PatientAlertsTableContent[];
    }
  );

  const patientAlertTableData = data as PatientAlertsTableContent[];

  return {
    patientAlertTable: patientAlertTableData?.map((alertData) => {
      return {
        ...alertData,
      } as PatientAlertsTableContent;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export async function resolveTrigger(
  idList: string[],
  patientId: number,
  outcome?: any,
  note?: string
) {
  const { data, status } = await patientService.resolveTriggerAlert(
    idList,
    patientId,
    outcome,
    note
  );
  return { success: status >= 200 && status < 300, data };
}

export function usePatientDetailsDropdownItems({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError, refetch } = useQuery(
    [`patient-details-dropdown-items-${patientId}`, patientId],
    async () => {
      const { data } = await patientService.getPatientDetailsDropDownItems();

      return {
        countryItems: data?.countryItems,
        conditionItems: data?.conditionItems,
      } as IPatientDetailsDropdownItems;
    }
  );

  const queryClient = useQueryClient();

  const cancelQuery = () => {
    queryClient.cancelQueries([
      `patient-details-dropdown-items-${patientId}`,
      patientId,
    ]);
  };

  return {
    dropdownItems: data as IPatientDetailsDropdownItems,
    isFetching,
    isError,
    refetch,
    cancelQuery,
  };
}

export function useUserPreferencesData() {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`user-preferences`],
    async () => {
      const { data } = await userService.getUserPreferences();

      return {
        bloodGlucoseUnit: data.bloodGlucoseUnit,
        created: data.created,
        creatinineUnit: data.creatinineUnit,
        distanceUnit: data.distanceUnit,
        fitnessDataSource: data.fitnessDataSource,
        flowRateUnit: data.flowRateUnit,
        fluidsDashboard: data.fluidsDashboard,
        heightUnit: data.heightUnit,
        id: data.id,
        liquidUnit: data.liquidUnit,
        modified: data.modified,
        order: data.order,
        phosphateUnit: data.phosphateUnit,
        publish: data.publish,
        temperatureUnit: data.temperatureUnit,
        uid: data.uid,
        userId: data.userId,
        weightUnit: data.weightUnit,
        dataDisplayPreference: data.dataDisplayPreference,
        patientDetailsCardsOrder: data.patientDetailsCardsOrder,
      } as IUserPreferences;
    }
  );

  return {
    userPreferences: data as IUserPreferences,
    isFetching,
    isError,
    isRefetching,
    refetch,
  };
}
