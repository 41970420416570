import { Table } from "@patientmpower/spiro";

import { ReactComponent as NoMessagesImage } from "../../../../../../assets/images/woman-computer.svg";
import { createColumn } from "../../../../../../components/Table";
import { tooltipTitles } from "../../../../../../constants/measurements";
import {
  ImageContainer,
  NoContentMessage,
  TableContainer,
} from "./ModalMeasurementsTable.styles";

interface IModalMeasurements {
  data: Map<string, any[]>;
}

interface IMeasuremntTableData {
  measurement: string;
  source: string;
  time: string;
  unformattedTime: number;
}

export function ModalMeasurementsTable({ data }: IModalMeasurements) {
  if (data.size < 1) {
    return (
      <ImageContainer>
        <NoMessagesImage width={181} height={158} />
        <NoContentMessage>
          No measurements to show with the current filters and/or data range.
        </NoContentMessage>
      </ImageContainer>
    );
  }

  const mapKeys = Array.from(data.keys());
  let header = tooltipTitles.get(mapKeys[0].split("|")[0]) ?? "";
  const tableData: IMeasuremntTableData[] = [];

  mapKeys.forEach((key) => {
    const measurementType = key.split("|")[0];

    data.get(key)?.forEach((measurement) => {
      if (measurementType === "bp_systolic") {
        header = "Systolic/Diastolic";
        const DiastolicMeasurement = data
          .get(`bp_diastolic|${measurement.source.toLowerCase()}`)
          ?.find((element) => element.id === measurement.id);
        tableData.push({
          measurement: `${measurement[measurementType]}/${
            DiastolicMeasurement ? DiastolicMeasurement.bp_diastolic : "-"
          }`,
          source: measurement.source,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
        });
      } else if (measurementType !== "bp_diastolic") {
        tableData.push({
          measurement: measurement[key.split("|")[0]],
          source: measurement.source,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
        });
      }
    });
  });

  tableData.sort((a, b) => b.unformattedTime - a.unformattedTime);

  const ModalMeasurementsTableColumns = [
    createColumn({
      keyName: "measurement",
      header: () => header,
      cell: (info) => info.renderValue(),
      size: 80,
    }),
    createColumn({
      keyName: "source",
      header: () => "Source",
      cell: (info) => info.renderValue(),
      size: 80,
    }),
    createColumn({
      keyName: "time",
      header: () => "Date",
      cell: (info) => info.renderValue(),
      size: 200,
    }),
  ];
  return (
    <TableContainer>
      <Table
        fontWeight={400}
        hasBorder={false}
        data={tableData}
        columns={ModalMeasurementsTableColumns}
      />
    </TableContainer>
  );
}
