import { useEffect, useState } from "react";

import useIsMobile from "../../../hooks/useIsMobile";
import { CustomChartLegend } from "../../CustomChartLegend/CustomChartLegend";
import {
  ChartLegendContainer,
  Container,
  Divider,
  MainValueContainer,
  MainValueContainerFirst,
  MainValuesContainer,
  NumberLabel,
  TextLabel,
} from "./CustomModalChartLegend.style";

export function CustomModalChartLegend(props: any) {
  const { mainValues, empty, isBloodPressure } = props;
  const { min, max, average } = mainValues;

  const { isMobile } = useIsMobile();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!min && !max && !average);
  }, [mainValues]);

  const chartLegendContainer = (
    <ChartLegendContainer>
      <CustomChartLegend {...props} focusMode />
    </ChartLegendContainer>
  );

  const mainValuesContainer = (
    <MainValuesContainer>
      {isBloodPressure ? (
        <>
          <MainValueContainerFirst>
            <NumberLabel disabled={disabled}>{max || "-"}</NumberLabel>
            <TextLabel disabled={disabled}>Highest</TextLabel>
          </MainValueContainerFirst>
          <MainValueContainer>
            <NumberLabel disabled={disabled}>{average || "-"}</NumberLabel>
            <TextLabel disabled={disabled}>Mean</TextLabel>
          </MainValueContainer>
          <MainValueContainer>
            <NumberLabel disabled={disabled}>{min || "-"}</NumberLabel>
            <TextLabel disabled={disabled}>Lowest</TextLabel>
          </MainValueContainer>
        </>
      ) : null}
    </MainValuesContainer>
  );

  return (
    <div
      style={{ bottom: !isMobile ? "-16px" : undefined, position: "relative" }}
    >
      {isMobile ? (
        <Container>
          {chartLegendContainer}
          {!empty && <Divider />}
          {mainValuesContainer}
        </Container>
      ) : (
        <Container>
          {mainValuesContainer}
          {!empty && <Divider />}
          {chartLegendContainer}
        </Container>
      )}
    </div>
  );
}
