import { useQuery } from "@tanstack/react-query";

import { IAvailableWards } from "../../@types/User";
import { wardsService } from "../../services/wardsService";

export function useWards() {
  const { data, isError, isLoading, isFetching, refetch, isRefetching } =
    useQuery(
      ["wards-options"],
      async () => {
        const { data } = await wardsService.getAvailableWards();

        return data;
      },
      {
        refetchOnMount: true,
      }
    );

  return {
    wards: data as IAvailableWards[],
    isFetching,
    isError,
    refetch,
    isRefetching,
    isLoading,
  };
}
