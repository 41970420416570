import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import {
  ISectionProps,
  PatientMedicationsTableContent,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { getPatientMedicationsData } from "../../../../hooks/queries/medications";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { PatientMedicationsTable } from "../PatientMedicationsTable";
import { NewDataCOntainer, TitleContainer } from "./MedicationSection.styles";

export function MedicationsSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { isMobile } = useIsMobile();
  const highlightedRows = [false];

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const {
    patientMedicationTable,
    isError,
    medicationsMaxDate,
    isFetching,
    refetch,
    isRefetching,
  } = getPatientMedicationsData(patient.id);

  const handleRefreshTable = () => {
    refetch();
  };

  const parsePatientMedicationsToTable = useMemo(() => {
    if (!patientMedicationTable) return [];

    return patientMedicationTable.map(
      (patientMedication: PatientMedicationsTableContent) => {
        return {
          id: patientMedication.id,
          name: patientMedication.name,
          time: patientMedication.time,
          interval: patientMedication.interval,
          source: patientMedication.source,
        };
      }
    );
  }, [patientMedicationTable]);

  useEffect(() => {
    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      patientMedicationTable &&
      patientMedicationTable.length > 0
    ) {
      const mostRecentMedicationDate = medicationsMaxDate
        ? dayjs(medicationsMaxDate)
        : dayjs("2000-01-01");
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      if (mostRecentMedicationDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  }, [patientMedicationTable]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("medicationsSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Medication</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientMedicationsTable
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={parsePatientMedicationsToTable}
          highlightedRows={highlightedRows}
          refreshTable={handleRefreshTable}
        />
      </div>
    </>
  );
}
