import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { useState } from "react";
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain, AxisInterval } from "recharts/types/util/types";

import useIsMobile from "../../hooks/useIsMobile";
import { DateFilterOptionType } from "../../pages/PatientPage/components/MeasurementModal/components/CustomOptionsBar";
import { getGraphSeparators } from "../../utils/chartUtils";
import { ChartConfig, ChartData } from "../ChartCard/ChartCard.types";
import { CustomRechartsDot } from "../CustomRechartsDot/CustomRechartsDot";
import { CustomRechartsTooltip } from "../CustomRechartsTooltip/CustomRechartsTooltip";
import { CustomModalChartDot } from "./CustomModalChartDot/CustomModalChartDot";
import { CustomModalChartLegend } from "./CustomModalChartLegend/CustomModalChartLegend";
import { CustomModalChartTick } from "./CustomModalChartTick/CustomModalChartTick";

type MeasurementPayloadType = {
  fvc: number;
  time: string;
  unformattedTime: string;
  spirometryTestIds: number[];

  cx: number;
  cy: number;
};

type ModalChartProps = {
  xConfig?: {
    label?: any;
    dataKey?: string;
    axisSize?: number;
    minTickGap?: number;
    interval?: AxisInterval;
    allowDecimals?: boolean;
    type?: "number" | "category";
    ticks?: Array<number | string>;
    tickFormatter?: (value: string) => string;
  };
  yConfig: {
    axisSize?: number;
    domain?: AxisDomain;
    label?: any;
  };
  data: Map<string, ChartData[]> | Map<string, any[]>;
  mainValues: any;
  width: string | number;
  height: string | number;
  disableTooltip?: boolean;
  chartConfig: ChartConfig[];
  onRowClick?: (payload: MeasurementPayloadType) => void;
  dateInterval?: DateFilterOptionType;
  isSpirometry?: boolean;
  isBloodPressure?: boolean;
};

export function ModalChart({
  data,
  mainValues,
  width,
  height,
  xConfig,
  yConfig,
  onRowClick,
  chartConfig,
  disableTooltip,
  dateInterval,
  isSpirometry,
  isBloodPressure = false,
}: ModalChartProps) {
  const [selectedPoint, setSelectedPoint] =
    useState<MeasurementPayloadType | null>(null);

  const { isMobile } = useIsMobile();

  dayjs.extend(AdvancedFormat);

  let referenceTicks: number[] = [];

  const {
    graphSeparators,
    dates,
    separatorCounter,
    tickFormat,
    viewType,
    isTickMultipleLine,
  } = getGraphSeparators(dateInterval, mainValues.minDate, mainValues.maxDate);

  const tickXFormatter = (value: string) => {
    if (!data || !data.size) return "";
    if (xConfig?.dataKey) return value;

    if (
      graphSeparators.includes(Number(value)) ||
      referenceTicks.includes(Number(value))
    ) {
      if (tickFormat === "Q") {
        return (
          value &&
          `Q${dayjs(value).format(tickFormat)}, ${dayjs(value).format("YY")}`
        );
      }

      return value && dayjs(value).format(tickFormat);
    }

    return "";
  };

  const handleOnClickOverActiveDots = (selectedPointInfo: any) => {
    if (onRowClick && isSpirometry) {
      setSelectedPoint(selectedPointInfo);
      onRowClick(selectedPointInfo as MeasurementPayloadType);
    }
  };

  const handleXDomain = () => {
    if (!dateInterval || !data) return [0, 1];

    const { minDate, maxDate } = mainValues;
    let { startDate, endDate } = dateInterval;

    if (minDate && maxDate && endDate.diff(startDate, "year") > 5) {
      startDate = minDate;
      endDate = maxDate;
    }

    return [startDate.valueOf(), endDate.valueOf()];
  };

  const refs: number[] = [
    ...graphSeparators,
    ...dates.filter((_, index) => index % separatorCounter === 0),
  ];

  referenceTicks = [...refs];

  const allSourcesData = Array.from(data.values()).flat();

  return (
    <ResponsiveContainer debounce={10} width={width} height={height}>
      <ScatterChart margin={{ right: 20, top: 8 }} data={allSourcesData}>
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={<CustomRechartsTooltip data={allSourcesData} />}
            wrapperStyle={{ outline: "none" }}
          />
        )}
        <CartesianGrid strokeDasharray="2" vertical={false} />
        <YAxis
          tickMargin={8}
          stroke="#D8D8D8"
          allowDecimals={false}
          domain={yConfig.domain}
          width={yConfig.axisSize || 48}
          tick={{
            fill: "#727272",
            fontSize: isMobile ? "10px" : "12px",
            fontWeight: 400,
          }}
        />
        <XAxis
          tickMargin={14}
          stroke="#D8D8D8"
          type="number"
          ticks={viewType === "yearly" ? graphSeparators : dates}
          domain={handleXDomain()}
          tickFormatter={xConfig?.tickFormatter || tickXFormatter}
          allowDuplicatedCategory={false}
          height={xConfig?.axisSize || 48}
          dataKey="unformattedTime"
          allowDecimals={xConfig?.allowDecimals}
          minTickGap={xConfig?.minTickGap || 100}
          interval={xConfig?.interval || 0}
          tick={
            isTickMultipleLine ? (
              <CustomModalChartTick />
            ) : (
              {
                fill: "#727272",
                fontSize: isMobile ? "10px" : "12px",
                fontWeight: "400",
              }
            )
          }
          angle={refs.length >= 15 || isMobile ? 30 : 0}
        />

        {refs.map((ref) => (
          <ReferenceLine
            key={ref.toString()}
            x={ref}
            stroke="#D8D8D8"
            strokeDasharray="8 1000"
          />
        ))}

        <Legend
          content={
            <CustomModalChartLegend
              mainValues={mainValues}
              empty={allSourcesData.length === 0}
              isBloodPressure={isBloodPressure}
            />
          }
        />

        {chartConfig.map((currentChartConfig: ChartConfig, index: number) => {
          const {
            yKey,
            stroke,
            color,
            dotShape,
            isDotClickable,
            selectedSpirometryTestId,
            lineOpacity,
            source,
          } = currentChartConfig;

          const dataSource = source ? data.get(`${yKey}|${source}`) : [];

          return (
            <Scatter
              key={`${yKey}_${index}_${color}`}
              fill={color}
              line={{
                stroke: color,
                strokeWidth: stroke || 1.4,
                opacity: lineOpacity,
              }}
              lineJointType="monotoneX"
              isAnimationActive={false}
              shape={
                isDotClickable ? (
                  <CustomModalChartDot
                    selectedValue={selectedPoint}
                    color={color}
                    onDotClick={handleOnClickOverActiveDots}
                    selectedSpirometryTestId={selectedSpirometryTestId}
                  />
                ) : (
                  <CustomRechartsDot fill={color} shape={dotShape} />
                )
              }
              dataKey={yKey}
              data={dataSource}
              name={source}
            />
          );
        })}
      </ScatterChart>
    </ResponsiveContainer>
  );
}
