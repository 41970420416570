import { IRoutePattern } from "../@types/Route";
import { CalendarOneDot } from "../assets/icons/CalendarOneDot";
import { CircleTickPlus } from "../assets/icons/CircleTickPlus";
import { Lab } from "../assets/icons/Lab";
import { Panel } from "../assets/icons/Panel";

export const mainRoutes: IRoutePattern[] = [
  {
    name: "Patients",
    path: "/patients",
    disabled: false,
    Icon: Panel,
  },
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  //   disabled: true,
  //   Icon: Vitals,
  // },
  {
    name: "Surveys",
    path: "/surveys",
    disabled: true,
    Icon: Lab,
  },
  {
    name: "Protocol",
    path: "/protocol",
    disabled: true,
    Icon: CircleTickPlus,
  },
  {
    name: "Appointments",
    path: "/appointments",
    disabled: true,
    Icon: CalendarOneDot,
  },
  {
    name: "Care Info",
    path: "/careinfo",
    disabled: true,
    Icon: Lab,
  },
];
