import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { IPortalPreferences } from "../@types/Preferences";
import {
  AUTH_GRAPH_PREFERENCES,
  AUTH_PORTAL_PREFERENCES,
} from "../constants/localStorageKeys";

type UserPreferencesContextData = {
  preferences: UserPreferences;
  changeUserPreferences: (userPreferences: UserPreferences) => void;
  portalPreferences: IPortalPreferences;
  changePortalPreferences: (portalPreferences: IPortalPreferences) => void;
};

type UserPreferencesProviderProps = {
  children: ReactNode;
};

type UserPreferences = {
  hideFvcRejected: boolean;
  hideFev1Rejected: boolean;
  hideFvcUsable: boolean;
  hideFev1Usable: boolean;
};

export const UserPreferencesContext = createContext(
  {} as UserPreferencesContextData
);

export function UserPreferencesProvider({
  children,
}: UserPreferencesProviderProps) {
  const [userPreferences, setUserPreferences] = useState<UserPreferences>(
    () => {
      const userPreferencesStored = localStorage.getItem(
        AUTH_GRAPH_PREFERENCES
      );

      let userPreferencesStoredParsed = null;
      try {
        userPreferencesStoredParsed =
          userPreferencesStored && JSON.parse(userPreferencesStored);
      } catch (error) {
        console.log("error", error);
      }

      return userPreferencesStoredParsed;
    }
  );

  const [portalPreferences, setPortalPreferences] =
    useState<IPortalPreferences>(() => {
      const storedPortalPreferences = localStorage.getItem(
        AUTH_PORTAL_PREFERENCES
      );
      if (storedPortalPreferences) {
        return JSON.parse(storedPortalPreferences);
      }

      return "";
    });

  useEffect(() => {
    localStorage.setItem(
      AUTH_GRAPH_PREFERENCES,
      JSON.stringify(userPreferences)
    );
  }, [userPreferences]);

  useEffect(() => {
    localStorage.setItem(
      AUTH_PORTAL_PREFERENCES,
      JSON.stringify(portalPreferences)
    );
  }, [portalPreferences]);

  const handleOnChangeUserPreferences = (userPreferences: UserPreferences) => {
    setUserPreferences(userPreferences);
  };

  const handleOnChangePoratlPreferences = (
    portalPreferences: IPortalPreferences
  ) => {
    setPortalPreferences(portalPreferences);
  };

  const userPreferencesValues = useMemo(() => {
    return {
      preferences: userPreferences,
      changeUserPreferences: handleOnChangeUserPreferences,
      portalPreferences,
      changePortalPreferences: handleOnChangePoratlPreferences,
    };
  }, [userPreferences, portalPreferences]);

  return (
    <UserPreferencesContext.Provider value={userPreferencesValues}>
      {children}
    </UserPreferencesContext.Provider>
  );
}
