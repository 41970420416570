import { css, styled } from "@patientmpower/spiro";

export const DatepickerContainer = styled("div", {
  top: 128,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",
});

export const FilterContainer = styled("div", {
  top: 128,
  left: 390,
  minWidth: 204,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  background: "white",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",
});

export const RadioGroupContainer = styled("div", {
  width: "100%",
  padding: "10px 34px 16px 26px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  fontFamily: "$openSans",
  color: "#4B4B4B",

  b: {
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: "600",
    padding: "8px 0px 10px 0",
  },

  label: {
    input: {
      cursor: "pointer",
    },
  },

  "&:last-child": {
    paddingTop: "0px",
  },
});

export const RadioButtonContainer = styled("div", {
  width: "100%",
  height: "24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "3px 3px 6px 0",

  p: {
    fontSize: "10px",
    lineHeight: "13px",
    fontWeight: "400",
  },

  label: {
    transform: "translateY(-4px)",
  },
});

export const OptionDivider = styled("div", {
  width: "100%",
  height: "1px",
  backgroundColor: "#E6E6E6",
  borderRadius: "0.5px",
});

export const OptionsBarContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  gap: "7px",
});

export const SvgCss = css({
  svg: {
    margin: "0px !important",
    width: "auto !important",
  },
});
