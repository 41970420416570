import { RadioButton } from "@patientmpower/spiro";
import { useEffect, useState } from "react";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
  ISurveyQuestionOption,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import { ClickableOption } from "../FiveQuestionsAboutSupOxDisplay/FiveQuestionsAboutSupOxDisplay.styles";
import {
  FormAnswerField,
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";
import {
  OptionsSelectionContainer,
  RadioButtonContainer,
  RadioLabel,
} from "./SingleSelectQuestionDisplay.styles";

export function SingleSelectQuestionDisplay({
  title,
  description,
  options,
  questionId,
  canModify,
}: IQuestionDisplayProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const { surveyAnswerArray, updateSurveyAnswerArrayOnOptionClick } =
    useDynamicsurveys();

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );
    if (storedAnswer !== undefined) {
      const valueToSearch = parseFloat(storedAnswer?.answerValue);
      const updateIndex = options?.findIndex(
        (option) => option.value === valueToSearch
      );
      setSelectedIndex(updateIndex);
    }
  }, []);

  const updateAnswerArray = (value: string) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "no_id",
      answerValue: value,
    };
    updateSurveyAnswerArrayOnOptionClick(answerUpdate);
  };

  const handleClick = (index: number) => {
    if (canModify) {
      if (selectedIndex !== index && options) {
        setSelectedIndex(index);
        const valueInNunmber: number = options[index].value;
        updateAnswerArray(valueInNunmber.toString());
      } else {
        setSelectedIndex(undefined);
        updateAnswerArray("");
      }
    }
  };

  return (
    <FormAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>
      {options?.map((option: ISurveyQuestionOption, index: number) => (
        <OptionsSelectionContainer key={index}>
          <ClickableOption
            onClick={() => {
              handleClick(index);
            }}
          >
            <RadioButtonContainer>
              <RadioButton
                style={{
                  height: "auto",
                  width: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                circleStyle={{ height: "15px", width: "15px" }}
                onClick={() => {
                  handleClick(index);
                }}
                checked={selectedIndex === index}
              />
            </RadioButtonContainer>
            <RadioLabel>{option.label}</RadioLabel>
          </ClickableOption>
        </OptionsSelectionContainer>
      ))}
    </FormAnswerField>
  );
}
