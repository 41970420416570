/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import {
  createColumnHelper,
  HeaderContext,
  CellContext,
  Header,
  flexRender,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useVirtual } from "react-virtual";

import {
  EmptyStateContainer,
  TableContainer,
  TableElement,
  Th,
  Tr,
} from "./Table.styles";

const columnHelper = createColumnHelper<any>();

type ColumnType<T> = {
  keyName: string;
  header: (headerValue: HeaderContext<T, string>) => string;
  cell: (cellValue: CellContext<T, any>) => string | null;
  size?: number;
};

export function createColumn<T>(column: ColumnType<T>) {
  return columnHelper.accessor(column.keyName, {
    header: column.header,
    cell: column.cell,
    size: column.size,
  });
}

function setFlexRender(header: Header<any, unknown>) {
  return header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.header, header.getContext());
}

interface ITableProps {
  columns: ColumnDef<any, any>[];
  data: any[];
  onRowClick?: (rowContent: any) => void;
  emptyStateText?: string;
  highlightedRows?: boolean[];
  highlightColor?: string | string[];
}

export function Table({
  columns,
  data,
  emptyStateText,
  onRowClick,
  highlightedRows = new Array(columns?.length).fill(false),
  highlightColor = "white",
}: ITableProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [rowsToHighlight, setRowsToHighlight] = useState<boolean[]>([]);
  const { rows } = table.getRowModel();

  useEffect(() => {
    setRowsToHighlight(highlightedRows);
  }, [highlightedRows]);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: rows.length,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const hasData = Boolean(data && data.length);

  let tablePrimaryColor = "white";
  let tableSecondaryColor = "";

  const isColorsArray = Array.isArray(highlightColor);

  if (isColorsArray) {
    let index = 0;
    tablePrimaryColor = highlightColor[index];
    index = +1;
    tableSecondaryColor = highlightColor[index];
  }

  const handleTableBackgroundColor = (rowId: number) => {
    if (rowId % 2 === 0) {
      return tablePrimaryColor;
    }

    return tableSecondaryColor;
  };

  return (
    <TableContainer emptyState={!hasData} ref={tableContainerRef}>
      <TableElement>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th style={{}} key={header.id}>
                  {setFlexRender(header)}
                </Th>
              ))}
            </tr>
          ))}
        </thead>

        {hasData && (
          <tbody>
            <tr style={{ backgroundColor: tablePrimaryColor }}>
              <td aria-label="paddingTop" style={{ height: `$2px` }} />
            </tr>
            {paddingTop > 0 && (
              <tr style={{ backgroundColor: tablePrimaryColor }}>
                <td
                  aria-label="customPaddingTop"
                  style={{ height: `${paddingTop}px` }}
                />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              const handleOnRowClick = () => {
                if (!onRowClick) return;

                onRowClick(row.original);
              };

              return (
                <Tr
                  onClick={handleOnRowClick}
                  tabIndex={0}
                  key={row.id}
                  style={{
                    backgroundColor: rowsToHighlight[Number(row.id)]
                      ? handleTableBackgroundColor(Number(row.id))
                      : "",
                  }}
                  alert={rowsToHighlight[Number(row.id)]}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      // style={cell.column.id === "value" ? {
                      //   textDecoration: "underline",
                      //   textUnderlineOffset: "4px",
                      // } : {}}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </Tr>
              );
            })}

            {paddingBottom > 0 && (
              <tr style={{ backgroundColor: tablePrimaryColor }}>
                <td
                  aria-label="customPaddingBottom"
                  style={{ height: `${paddingBottom}px` }}
                />
              </tr>
            )}
          </tbody>
        )}

        {hasData && <tfoot />}
      </TableElement>

      {!hasData && (
        <EmptyStateContainer>
          <p>{emptyStateText || "No data to show"}</p>
        </EmptyStateContainer>
      )}
    </TableContainer>
  );
}