import axios from "axios";

import { IToken } from "../@types/Login";
import { AUTH_TOKEN_STORAGE } from "../constants/localStorageKeys";

export const checkIfUserIsAuthenticated = ({ status }: { status: number }) => {
  const isUserUnauthenticated = status === 401;

  if (isUserUnauthenticated) {
    localStorage.clear();
    window.location.assign("/login");
  }
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

api.interceptors.request.use((request) => {
  let parsedTokenResponse: IToken = {
    tokenType: "",
    accessToken: "",
    idToken: "",
    refreshToken: "",
    expiresIn: 0,
  };

  const tokenResponse = localStorage.getItem(AUTH_TOKEN_STORAGE);

  if (tokenResponse != null) {
    parsedTokenResponse = JSON.parse(tokenResponse);
  }

  if (request.headers) {
    if (parsedTokenResponse.idToken) {
      request.headers.Authorization = `${parsedTokenResponse.tokenType} ${parsedTokenResponse.idToken}`;
    }
  }

  return request;
});

api.interceptors.response.use(
  (response) => {
    checkIfUserIsAuthenticated({ status: response.status });

    return response;
  },
  (error) => {
    checkIfUserIsAuthenticated({ status: error.response.status });
    return error.response;
  }
);
