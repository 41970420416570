import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SelectMeasurementPayloadType } from "../../../../@types/Measurements";
import { MeasurementSection } from "./components/MeasurementSection";
import { SpirometrySection } from "./components/SpirometrySection";
import { Container } from "./MeasurementModal.styles";
import { MeasurementModalProps } from "./MeasurementModal.types";

export function MeasurementModal({
  cardTitle,
  patientId,
  measurementType,
  minValue,
  maxValue,
  isSpirometry,
}: MeasurementModalProps) {
  const [
    selectedSpirometryMeasurementValue,
    setSelectedSpirometryMeasurementValue,
  ] = useState<SelectMeasurementPayloadType | null>(null);

  const [hideSpirometrySection, setHideSpirometrySection] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const spirometryTestId = searchParams.get("spirometryTestId");
    if (spirometryTestId) {
      setSelectedSpirometryMeasurementValue({
        unformattedTime: "",
        time: "",
        fvc: 0,
        spirometryTestIds: [parseInt(spirometryTestId, 10)],
      });
    }
  }, [searchParams]);

  return (
    <Container>
      <MeasurementSection
        cardTitle={cardTitle}
        patientId={patientId}
        measurementType={measurementType}
        minValue={minValue}
        maxValue={maxValue}
        onSelectChartValue={(selectedChartValue) => {
          // Show a snackbar if the spirometry value is undefined
          setSelectedSpirometryMeasurementValue(selectedChartValue);
        }}
        onSelectedTableView={(showMeasurementsTable) => {
          setHideSpirometrySection(showMeasurementsTable);
        }}
        isSpirometry={isSpirometry}
      />

      {selectedSpirometryMeasurementValue?.spirometryTestIds &&
        !hideSpirometrySection && (
          <SpirometrySection
            selectedMeasurement={selectedSpirometryMeasurementValue}
          />
        )}
    </Container>
  );
}
