import { Checkbox } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
  ISurveyQuestionOption,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import { ClickableOption } from "../FiveQuestionsAboutSupOxDisplay/FiveQuestionsAboutSupOxDisplay.styles";
import { OptionsSelectionContainer } from "../SingleSelectQuestionDisplay/SingleSelectQuestionDisplay.styles";
import {
  FormAnswerField,
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";

export function MultiSelectQuestionDisplay({
  title,
  description,
  options,
  questionId,
}: IQuestionDisplayProps) {
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  const multiSelectFormik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );

    if (storedAnswer !== undefined && storedAnswer.answerValue !== "") {
      const optionsValuesList = storedAnswer.answerValue.split("|");
      const optionsValuesListParsed = optionsValuesList.map(Number);
      setSelectedValues(optionsValuesListParsed);
    }
  }, []);

  const handleOptionClick = (option: ISurveyQuestionOption) => {
    const updatedSelectedValues = [...selectedValues];
    if (
      selectedValues.find((selectedValue) => selectedValue === option.value) ===
      undefined
    ) {
      updatedSelectedValues.push(option.value);
    } else {
      const indexToRemove = selectedValues.findIndex(
        (selectedValue) => selectedValue === option.value
      );
      updatedSelectedValues.splice(indexToRemove, 1);
    }
    updatedSelectedValues.sort();
    setSelectedValues(updatedSelectedValues);
  };

  const updateAnswerArray = (value: string) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: value,
    };
    updateSurveyAnswerArray(answerUpdate);
  };

  useEffect(() => {
    const parsedSelectedValues = selectedValues.join("|");
    multiSelectFormik.setFieldValue("answer", parsedSelectedValues);
    updateAnswerArray(parsedSelectedValues);
  }, [selectedValues]);

  return (
    <FormAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>
      {options?.map((option: ISurveyQuestionOption, index: number) => (
        <OptionsSelectionContainer key={index}>
          <ClickableOption
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            <Checkbox
              key={`${index}`}
              label=""
              checked={selectedValues.includes(option.value)}
              onChange={() => {}}
            />
            <p style={{ marginLeft: "10px" }}>{option.label}</p>
          </ClickableOption>
        </OptionsSelectionContainer>
      ))}
    </FormAnswerField>
  );
}
